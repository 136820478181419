@use 'mixins/mixin';

@include mixin.mobile() {
  .body {
    // Instead of NoopScrollStrategy
    .cdk-global-scrollblock {
      overflow: visible;
    }
    &.LIGHT,
    &.DARK_BLUE,
    &.DARK {
      min-width: 320px;
      overflow-x: hidden;
      .intercom-lightweight-app {
        display: none;
      }
      .mat-form-field-label {
        transition: none;
      }
      .cdk-overlay-backdrop {
        transform: none;
      }
      .wrap {
        padding: 52px 0 24px;
      }
      .mat-table thead {
        display: none;
      }
      .cdk-global-overlay-wrapper .cdk-overlay-pane.largeModalWindow,
      .cdk-global-overlay-wrapper .cdk-overlay-pane.mediumModalWindow {
        // Override matDialogConfig values
        max-width: 100vh !important;
        width: 100% !important;
        height: 100% !important;
        min-width: auto !important;
        margin: 0 !important;
        max-height: 100vh !important;
      }
      .cdk-overlay-pane.mat-snack-bar-handset {
        margin: 0 auto;
      }
      :focus {
        outline: none;
      }
      .expanded-table .tr.mat-footer-row,
      .expanded-table tr.mat-row {
        height: auto;
        min-height: auto;
      }
      .expanded-table.mat-table .mat-mdc-cell {
        display: block;
        font-size: 1em;
        text-align: right;
        font-weight: bold;
        height: auto;
        margin: 0;
      }
      .expanded-table.mat-table .mat-column-expandedDetail {
        padding: 0;
        border: none;
      }
      .expanded-table.mat-table .mat-mdc-cell span:before,
      .expanded-table.mat-table .mat-mdc-cell p:before,
      .expanded-table-detail td:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 0.85em;
      }
      .mat-radio-label {
        white-space: normal;
      }
      .mat-select .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        height: 70px;
      }
      .expanded-table-detail {
        thead {
          display: none;
        }
        tr {
          display: flex;
          flex-wrap: wrap;
        }
        td {
          display: flex;
          flex-direction: column;
        }
      }
      .responsiveTable.mat-table {
        font-size: 0.9em;
      }
      .responsiveTable.mat-table td {
        min-height: 35px;
      }
      .mobileStyle + div .mat-mdc-menu-panel.minWidthMatMenu {
        min-width: 320px;
        width: 100%;
      }
      .mobileStyle + .cdk-overlay-connected-position-bounding-box {
        left: 10px !important;
      }
      .cdk-overlay-container .mobileStyle + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
        width: calc(100% - 20px) !important;
        left: 0 !important;
        position: absolute !important;
        .mat-mdc-menu-panel {
          max-width: 100%;
        }
      }
      .responsiveTable.mat-table .mat-mdc-cell:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .intercom-launcher-frame {
        display: none !important;
      }
      .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
        top: 50%;
        transform: translateY(-50%);
      }
      .container {
        padding-bottom: 45px;
        &.resetPadding {
          padding-bottom: 0;
        }
      }
      .connectSxTable {
        table-layout: fixed;
        &.tableInForm {
          tr {
            th,
            td {
              // Override global styles
              width: calc(100vw - 48px) !important;
            }
          }
        }
      }
      .form-group-emphasized {
        margin-right: 0;
        padding: 16px;
        &.resetPaddingContainer {
          padding: 16px 0;
          .form-group-emphasized-wrap {
            padding: 0 16px;
          }
          .connectSxTable {
            tr {
              th,
              td {
                // Override global styles
                width: calc(100vw - 32px) !important;
              }
            }
          }
        }
      }
      .mobileLastChild {
        margin-bottom: 68px;
      }
      .mobileFirstChild {
        margin-top: 48px;
        &.expandedView {
          margin-top: 78px;
        }
        &.shortView {
          margin-top: 32px;
          &.expandedView {
            margin-top: 62px;
          }
        }
      }
      .mediumModalWindow,
      .largeModalWindow {
        .mat-mdc-dialog-container {
          border-radius: 0;
        }
      }
      .mediumModalWindow {
        .mediumModalWindowContent {
          overflow-y: auto;
          height: calc(100% - 112px);
        }
      }
      .confirmModalWindow {
        .mat-dialog-container {
          padding: 16px;
        }
      }
      .mat-stepper-horizontal-line {
        min-width: auto;
        margin: 0 6px;
      }
      .mat-step-header {
        &:last-child {
          margin-right: 0;
        }
        .mat-step-icon {
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .snack-error,
      .snack-warning,
      .snack-success {
        width: 100%;
        margin: 52px 16px 0;
      }
      // Password errors block styles
      .passwordErrorsInfo {
        .mat-card {
          background-color: var(--surface);
          border-radius: 8px;
          padding: 8px 12px;
          .mat-card-content {
            column-gap: 4px;
          }
        }
      }
      .actionButtonsContainer {
        padding: 16px;
      }
      .connectSxTabContainer {
        width: 100%;
      }
      .form-group-emphasized-3-col,
      .form-group-emphasized-2-col {
        grid-template-columns: 1fr;
        max-width: 100%;
      }
      .legendContainer {
        overflow-x: auto;
      }
      .largeModalWindowContent.small {
        .mat-horizontal-content-container {
          height: calc(100vh - 240px);
        }
      }
      .mat-horizontal-content-container {
        height: calc(100vh - 240px);
      }
    }
    ::-webkit-scrollbar:horizontal {
      -webkit-appearance: none;
      appearance: none;
      height: 0;
    }
    @media (max-width: 420px) {
      &.body {
        .mobileStyle + div .mat-mdc-menu-panel.minWidthMatMenu {
          min-width: 300px;
        }
      }
    }
    @media (max-width: 360px) {
      .mobileStyle + div .mat-mdc-menu-panel.minWidthMatMenu {
        min-width: 300px;
      }
    }
  }
}
